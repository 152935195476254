<template>
  <div style="padding: 20px">
    <div>
      <div class="title">
        {{ $route.meta.title }}
      </div>
      <!--搜索条件-->
      <el-card shadow="never" style="margin-top: 20px">
        <div class="card">
          <div class="card_t">
            <div class="card_t_icon"></div>
            <div class="card_t_text">筛选条件</div>
          </div>
          <div class="card_b">
            <!-- 搜索表单 -->
            <el-form
              :model="where"
              label-width="90px"
              label-position="left"
              class="ele-form-search"
              @keyup.enter.native="reload"
              @submit.native.prevent
            >
              <el-row :gutter="15">
                <el-col :lg="6" :md="14">
                  <el-form-item label-width="80px" label="关键字">
                    <el-input
                      v-model="where.keyword"
                      placeholder="司机姓名/电话"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="14">
                  <!-- {{value1}} -->
                  <el-form-item label-width="120px" label="抽奖起始时间">
                    <el-date-picker
                      value-format="yyyyMMdd"
                      v-model="where.start_time"
                      type="date"
                      placeholder="选择日期"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="14">
                  <!-- {{value1}} -->
                  <el-form-item label-width="120px" label="抽奖结尾时间">
                    <el-date-picker
                      value-format="yyyyMMdd"
                      v-model="where.end_time"
                      type="date"
                      placeholder="选择日期"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="14">
                  <el-form-item label-width="100px" label="司机属性">
                    <el-select
                      clearable
                      class="ele-block"
                      v-model="where.driver_identity"
                      placeholder="司机属性"
                    >
                      <el-option label="企业司机" value="self" />
                      <el-option label="个体司机" value="shared" />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="15">
                <el-col :lg="6" :md="14">
                  <el-form-item label-width="80px" label="司机归属">
                    <el-input
                      v-model="where.affiliation_name"
                      placeholder="请输入"
                      clearable
                    ></el-input>
                    <!--<el-select-->
                    <!--  clearable-->
                    <!--  class="ele-block"-->
                    <!--  v-model="where.affiliation_identity"-->
                    <!--  placeholder="司机属性"-->
                    <!--&gt;-->
                    <!--  <el-option label="企业" value="firm" />-->
                    <!--  <el-option label="个体" value="personal" />-->
                    <!--</el-select>-->
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col
                  :lg="24"
                  :md="12"
                  style="text-align: right"
                  class="flex-center"
                >
                  <div class="ele-form-actions">
                    <el-button
                      type="primary"
                      class="ele-btn-icon"
                      @click="QueryList"
                      >查询
                    </el-button>
                    <el-button @click="empty">清空</el-button>
                  </div>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-card>

      <!--数据表格-->
      <el-card shadow="never">
        <div class="card">
          <div class="Datalist">
            <div class="Datalist_l">
              <div class="Datalist_icon"></div>
              <div class="Datalist_text">数据列表</div>
              <span
                style="
                  font-size: 18px;
                  font-weight: 700;
                  color: #4e4e4e;
                  margin-left: 30px;
                "
                >合计：{{ total_money_sum }}</span
              >
              <!-- <span
                style="font-size: 18px; font-weight: 700; color: #fea837"
                >{{
              }}</span>
              <span
                style="
                  font-size: 18px;
                  font-weight: 700;
                  color: #4e4e4e;
                  margin-left: 20px;
                "
                >可用金额：{{ in_hand }}</span
              >
              <span
                style="font-size: 18px; font-weight: 700; color: #4e4e4e"
                >{{
              }}</span> -->
            </div>
            <div></div>
          </div>

          <div class="card_b">
            <!-- 数据表格 -->
            <my-table
              ref="myTable"
              :columns="columns"
              :tableData="List"
              :cellStyle="cellStyle"
              :headerCellStyle="headerCellStyle"
              :rowClassName="rowClassName"
              :customsFromWhere="where"
              :loading="loading"
              :total="total"
              @select="select"
              @ChangeSize="ChangeSize"
              @currentChange="currentChange"
            >
              <template v-slot:withdraw_time="{ scope }">
                <span>{{ scope.row.withdraw_time | formatDate }}</span>
              </template>
            </my-table>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
// 引入自定义表格组件
import MyTable from "@/components/MyTables";
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
import { lottery_record } from "@/api/finance";
// 权限
import { ShowTable } from "@/utils";

export default {
  name: "index",
  // 支柱道具属性
  props: {},
  // 组件生效
  components: {
    MyTable,
    PackageSortElement,
  },
  // Data数据
  data() {
    return {
      total_money_sum: 0,
      // 表格搜索条件
      //存储域名地址
      domain: "",
      where: {
        page: 1, // 当前页数
        page_size: 10, // 每页显示条目个数
      },
      dialogVisible: false,
      // 表格数据
      List: [],
      // 总条目数
      total: 0,
      //加载动画
      loading: true,
      //用来控制单条的数据
      row: {
        http: "",
        url: "",
      },
      // 表头数据
      columns: [
        {
          type: "selection",
          isShow: true,
        },
        {
          label: "订单号",
          prop: "odd",
          isShow: true,
        },
        {
          label: "抽奖时间",
          prop: "raffle_time",
          isShow: true,
        },
        {
          label: "中奖金额",
          prop: "intermediate_amount",
          isShow: true,
        },
        {
          label: "司机属性",
          prop: "driver_identity_label",
          isShow: true,
        },
        {
          label: "司机归属",
          prop: "affiliation_label",
          isShow: true,
        },
        {
          label: "司机",
          prop: "label",
          isShow: true,
          // slot: "withdraw_time",
          // isShow: true,
        },
      ],

      // 列表设置弹窗
      ListSetupShow: false,
      in_hand: 0,
    };
  },
  // // 生命周期 - 挂载完成（可以访问 DOM 元素）
  // mounted() {
  //   console.log("666666");
  //   this.getList();
  // },
  // updated(){
  //   this.getList();
  // },
  filters: {
    formatDate(value, format) {
      if (!value) return "";
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  activated() {
    console.log("666666");
    this.getList();
  },
  // 方法集合
  methods: {
    getList() {
      lottery_record(this.where).then((res) => {
        if (res.code == 200) {
          this.total = res.data.total;
          this.List = res.data.data;
          this.total_money_sum = res.data.total_money_sum;
          this.loading = false;
        } else {
          this.$Message.error(res.msg);
        }
      });
      // console.log("666666");
    },
    closet() {
      this.dialogVisible = false;
      this.domain = "";
    },
    // 查询条件
    QueryList() {
      this.where.page = 1;
      this.where.page_size = 10;
      this.getList();
    },
    // 清空
    empty() {
      this.where = {
        page: 1,
        page_size: 10,
      };
      this.getList();
    },
    // 当前页数改变事件
    currentChange(val) {
      // console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    // 当用户手动勾选数据行的 Checkbox 时触发的事件
    select(selection, row) {
      // console.log(selection)
      // console.log("选择的所有数据行", selection)
      // console.log("当前选择的数据行", row)
    },
    ChangeSize(val) {
      // console.log('当前页面显示的条数',val);
      this.where.page_size = val;
      this.getList();
    },
    // 表头行的 className 的回调方法
    headerRowClassName({ row, rowIndex }) {
      return "header_row_class_name";
    },
    // 表头单元格的 style 的回调方法
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background: #FAFAFA";
    },
    // 行的 className 的回调方法
    rowClassName({ row, rowIndex }) {
      return "row_class_name";
    },
    // 单元格的 style 的回调方法
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex == 1 && columnIndex == 3) {
        // return {
        //   background: "skyBlue",
        //   "border-radius": "20px"
        // }
      }
    },
    // 点击列表设置
    ListSetup() {
      this.ListSetupShow = true;
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  font-size: 24px;
  font-weight: 500;
  color: #707070;
  margin-bottom: 20px;
}

.condition {
  .condition_t {
    display: flex;
    align-items: center;
  }
  .condition_t_icon {
    width: 5px;
    height: 19px;
    background: #ff9b05;
  }
  .condition_t_text {
    font-size: 20px;
    font-weight: 700;
    color: #202033;
    margin-left: 15px;
  }
}
.flex {
  display: flex;
  align-items: center;
}
.w-50 {
  width: 400px;
}
.youbianjudingshirenwu {
  margin-left: 15px;
}
.zuobianjudingshirenwu {
  margin-right: 15px;
}
</style>
